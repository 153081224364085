import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import React from "react";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

interface IProps {
    locations: number[][]
}

L.Marker.prototype.options.icon = DefaultIcon;

export const MultiLocationMap = (
    {
        locations
    }: IProps
): React.ReactElement => {

    const getPosition = () => {
        if (locations.length > 0) {
            const location = locations[0];
            return { lat: location[0], lng: location[1] };
        }
        return { lat: 42.498354, lng: 1.503293 };
    }

    const renderLocations = () => {
        return locations
            .filter((location: number[]) => location && !isNaN(location[0]) && !isNaN(location[1]))
            .map((location: any) => {
                return (
                    <Marker position={location} key={`${location[0]} ${location[1]}`}>
                        <Popup>
                            {location[0]},{location[1]}
                        </Popup>
                    </Marker>
                )
            });
    }

    return (
        <div className="col-md-12 pt-2">
            <label>Mapa de localitzacions</label>
            <div className="form-group">
                <MapContainer style={{height: '400px', width: '100%'}} center={getPosition()} zoom={12} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    { renderLocations() }
                </MapContainer>
            </div>
        </div>
    )
}
