import React, {useState} from 'react';
import {
    FormRenderData,
    Module,
    ModalSize,
    FilterSelectorValuesParams,
    FieldFValue,
    getUrlEndpointPrivate,
} from '@cuatroochenta/co-admin-react-library';
import { MultiLocationMap } from './components/MultiLocationMap';
import './res/scss/custom-styles.scss'
import '@cuatroochenta/co-admin-react-library/lib/index.css';

interface Filters {
    [dynamic: string]: string
}

export interface IProviderDiscounts {
    oidProvider: number,
    discountsIds: number[],
}

export interface IProviderPacks {
    oidProvider: number,
    packsIds: number[],
}

export const App = ({}): React.ReactElement => {
    const [waitingProvidersDiscounts, setWaitingProvidersDiscounts] = useState<boolean>(false);
    const [providersDiscounts, setProvidersDiscounts] = useState<IProviderDiscounts[]|null>(null);
    const [waitingProvidersPacks, setWaitingProvidersPacks] = useState<boolean>(false);
    const [providersPacks, setProvidersPacks] = useState<IProviderPacks[]|null>(null);

    const beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        if (entity === 'pack' && !waitingProvidersDiscounts) {
            if (!providersDiscounts) {
                setWaitingProvidersDiscounts(true);
                const headers = { Authorization: 'Bearer ' + document.cookie.match('(^|;)\\s*' + 'AUTH_TOKEN' + '\\s*=\\s*([^;]+)')?.pop() || '' }
                fetch(
                    getUrlEndpointPrivate("provider-discounts"), { headers })
                    .then(response => response.json())
                    .then(data => {
                        setProvidersDiscounts(data.data);
                        setWaitingProvidersDiscounts(false);
                    });
            }
        }
        else {
            setProvidersDiscounts(null);
        }

        if (entity === 'discount' && !waitingProvidersPacks) {
            if (!providersPacks) {
                setWaitingProvidersPacks(true);
                const headers = { Authorization: 'Bearer ' + document.cookie.match('(^|;)\\s*' + 'AUTH_TOKEN' + '\\s*=\\s*([^;]+)')?.pop() || '' }
                fetch(
                    getUrlEndpointPrivate("provider-packs"), { headers })
                    .then(response => response.json())
                    .then(data => {
                        setProvidersPacks(data.data);
                        setWaitingProvidersPacks(false);
                    });
            }
        }
        else {
            setProvidersPacks(null);
        }
        return null;
    };

    const afterList = (): React.ReactElement => {
        if (providersDiscounts) {
            setProvidersDiscounts(null);
        }
        if (providersPacks) {
            setProvidersPacks(null);
        }
        return <></>;
    };

    const afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        if (entity === 'discount' || entity === 'plan' || entity === 'provider') {
            let locations = data.relationFormValues.locations.map(
                (location: any) => [location.latitude, location.longitude]
            );

            return (
                <MultiLocationMap locations={locations}></MultiLocationMap>
            )
        }

        return null;
    };

    const getSizeFilterModal = (entity: string): ModalSize | undefined => {
        if (entity === 'user') {
            return ModalSize.XL;
        }

        if (
            entity === 'userdiscount' ||
            entity === 'plan' ||
            entity === 'information' ||
            entity === 'userrenewrequest' ||
            entity === 'userpayment'
        ) {
            return ModalSize.LG;
        }
    };

    const filterSelectorValues = (
        filterSelectorValuesParams: FilterSelectorValuesParams
    ): FieldFValue[] => {
        if (filterSelectorValuesParams.entity === 'packdiscount') {
            if (providersDiscounts) {
                // hay un error en la librería y si se modifica un autosuggest, en vez de un number guarda un string
                // por eso las comparaciones se hacen con el operador == en vez de ===
                const provider = providersDiscounts.find((providerDiscounts: IProviderDiscounts) => {
                    return providerDiscounts.oidProvider == filterSelectorValuesParams.allFields.provider;
                });

                if (provider) {
                    return filterSelectorValuesParams.values.filter((selectorValue: FieldFValue) => {
                        return provider.discountsIds.find((discountId: number) => discountId == selectorValue.id);
                    });
                }

                return [];
            }
        }

        if (filterSelectorValuesParams.entity === 'packdiscount') {
            if (providersPacks) {
                // hay un error en la librería y si se modifica un autosuggest, en vez de un number guarda un string
                // por eso las comparaciones se hacen con el operador == en vez de ===
                const provider = providersPacks.find((providerPacks: IProviderPacks) => {
                    return providerPacks.oidProvider == filterSelectorValuesParams.allFields.provider;
                });

                if (provider) {
                    return filterSelectorValuesParams.values.filter((selectorValue: FieldFValue) => {
                        return provider.packsIds.find((packId: number) => packId == selectorValue.id);
                    });
                }

                return [];
            }
        }

        return filterSelectorValuesParams.values;
    }

    return (
        <Module
            actions={[]}
            afterFormView={afterFormView}
            beforeFormView={beforeFormView}
            afterList={afterList}
            locale={'ca'}
            getSizeFilterModal={getSizeFilterModal}
            filterSelectorValues={filterSelectorValues}
        />
    );

}
